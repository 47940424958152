import React from 'react'

const Icon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        className={className}
    >
        <g id="email">
            <path
                class="cls-1"
                d="M29.93,14.66A7.64,7.64,0,0,0,26,8.87V3.63A1.63,1.63,0,0,0,24.38,2H7.63A1.63,1.63,0,0,0,6,3.63V5A1,1,0,0,0,8,5V4H24V9.46h0v7.1l-7.06,3.8a2,2,0,0,1-1.88,0L8,16.56V10a1.19,1.19,0,0,0,0-.26,1,1,0,0,0,0-.67A1,1,0,0,0,6.6,8.55a7.66,7.66,0,0,0-4.53,6.11A1,1,0,0,0,2,15V26.84A3.17,3.17,0,0,0,5.16,30H21a1,1,0,0,0,0-2H5.16A1.16,1.16,0,0,1,4,26.84V16.67l10.12,5.45a3.92,3.92,0,0,0,3.76,0L28,16.67V26.84A1.16,1.16,0,0,1,26.84,28H25a1,1,0,0,0,0,2h1.84A3.17,3.17,0,0,0,30,26.84V15A1,1,0,0,0,29.93,14.66ZM6,11.26v4.22l-1.88-1A5.68,5.68,0,0,1,6,11.26Zm20,0a5.68,5.68,0,0,1,1.88,3.21l-1.88,1Z"
            />
            <path
                class="cls-1"
                d="M12.29,15.71a1,1,0,0,0,1.42,0l6-6a1,1,0,0,0-1.42-1.42l-6,6A1,1,0,0,0,12.29,15.71Z"
            />
            <path class="cls-1" d="M13,10a1,1,0,0,0,0-2h0a1,1,0,1,0,0,2Z" />
            <path class="cls-1" d="M19,14a1,1,0,1,0,1,1,1,1,0,0,0-1-1Z" />
        </g>
    </svg>
)

export default Icon
