import React from 'react'

const Icon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        className={className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.132,40.499l-6.47-1.217c-1.102-0.206-1.836-1.326-1.642-2.499
	c0.194-1.174,1.243-1.956,2.345-1.75l7.074,1.33c2.363,0.48,3.924,2.759,3.488,5.145c-0.01,0.054-0.027,0.102-0.037,0.155
	c-0.327,1.173-0.875,2.247-1.678,3.171c-4.615,5.305-15.559,3.777-24.443-3.416c-8.885-7.19-12.347-17.321-7.73-22.625
	c1.098-1.264,2.56-2.131,4.264-2.631c0.143-0.036,0.281-0.087,0.424-0.108c0,0,0.002-0.003,0.005-0.003
	c1.925-0.296,3.845,0.762,4.614,2.616l2.519,6.749c0.373,0.998-0.155,2.102-1.181,2.465c-1.023,0.364-2.155-0.15-2.528-1.148
	l-2.315-6.204c-0.191-0.409-0.654-0.623-1.091-0.475c-0.64,0.303-1.198,0.703-1.646,1.219c-3.195,3.673,0.043,11.37,7.236,17.19
	s15.615,7.563,18.809,3.891c0.197-0.228,0.361-0.473,0.508-0.729c0.038-0.072,0.07-0.147,0.084-0.232
	C45.807,40.977,45.535,40.594,45.132,40.499L45.132,40.499z M6,42c0,1.105-0.895,2-2,2s-2-0.895-2-2s0.895-2,2-2S6,40.895,6,42L6,42
	z M32,64c-5.025,0-9.772-1.173-14.002-3.239c-0.065-0.028-0.128-0.058-0.189-0.087s-0.124-0.056-0.185-0.087
	c-1.171-0.587-2.553-0.764-3.915-0.393c-0.026,0.007-0.053,0.012-0.08,0.019l-5.804,1.612c-0.021,0.005-0.046,0.005-0.068,0.013
	c-0.766,0.211-1.595,0.226-2.415,0c-2.422-0.665-3.845-3.166-3.18-5.588c0.014-0.056,0.019-0.098,0.031-0.148L3.889,50H4
	c0-1.105,0.895-2,2-2s2,0.895,2,2h0.041l-0.065,0.235c-0.024,0.216-0.08,0.419-0.169,0.608L6.012,57.31
	c0,0.004,0.007-0.003,0.007,0.002c-0.08,0.291,0.094,0.592,0.385,0.669c0.087,0.024,0.175,0.024,0.254,0.008
	c0.005,0-0.002,0.01,0,0.01l5.952-1.654c0.012-0.002,0.02-0.002,0.032-0.004c2.342-0.643,4.72-0.352,6.737,0.651
	c0.02,0.01,0.043,0.013,0.065,0.02C23.222,58.914,27.481,60,32,60c15.465,0,28-12.536,28-28S47.465,4,32,4C16.536,4,4,16.536,4,32
	c0,0.587,0.029,1.169,0.065,1.745l-0.09,0.008C3.985,33.833,4,33.915,4,34c0,1.105-0.895,2-2,2s-2-0.895-2-2
	c0-0.152,0.02-0.301,0.053-0.441C0.026,33.04,0,32.523,0,32C0,14.327,14.327,0,32,0s32,14.327,32,32S49.673,64,32,64L32,64z"
        />
    </svg>
)

export default Icon
