import React from 'react'

const Icon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className={className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M375.992 128h-39.984c-8.844 0-16 7.156-16 16v32.004c0 8.828 7.156 15.984 16 15.984h45.961c22.656 0 37.406 19.339 33.062 42.515l-8.984 47.918c-3.93 21.008-23.828 37.586-44.547 37.586h-25.492c-8.844 0-16 7.156-16 15.984v136.015c0 22.086-17.922 39.984-40.008 39.984-.305.023-26.422 0-40 0-8.828 0-16.008-7.156-16.008-16 0-8.828 7.18-16 16.008-16h32c8.844 0 16-7.156 16-15.984V328.007c0-22.102 17.906-40.008 40.008-40.008h28.914c8.328 0 16.852-6.648 19.07-15.031l8.484-31.953c2.477-9.309-2.812-17.023-11.852-17.023h-44.617c-22.102 0-40.008-17.902-40.008-40.003V136c0-22.102 17.906-40.003 40.008-40.003h39.984c8.844 0 16-7.16 16-16.004V47.988c0-8.824-7.156-15.984-16-15.984h-63.984c-44.188 0-80.016 35.809-80.016 79.992v71.992c0 22.101-17.898 40.003-39.984 40.003h-23.625c-9.039 0-16.383 7.715-16.383 17.023v31.953c0 8.383 6.75 15.031 15.07 15.031h24.938c22.086 0 39.984 17.906 39.984 40.008v39.983c0 8.844-7.156 16.008-15.984 16.008-8.844 0-16-7.164-16-16.008V335.99c0-8.828-7.172-15.984-16-15.984h-26.5c-20.719 0-37.508-16.578-37.508-37.586v-47.918c0-23.176 18.367-42.515 41.023-42.515h22.984c8.828 0 16-7.156 16-15.984v-72.008C192.008 46.555 238.562 0 296 0h79.992C398.094 0 416 17.902 416 39.988v48.004C416 110.093 398.094 128 375.992 128zM208.008 415.998c8.828 0 15.984 7.156 15.984 16 0 8.828-7.156 16.008-15.984 16.008-8.844 0-16-7.18-16-16.008 0-8.844 7.156-16 16-16z"
        />
    </svg>
)

export default Icon
