import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './favouritesList.module.css'
import Campsite from '../campsite'
import ShareButton from '../shareButton'

const FavouritesList = ({ filteredCampsites, parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)
    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <div>
                    <div className={classes.topbar}>
                        <ShareButton />
                    </div>
                    <div className={classes.campsiteContainer}>
                        {filteredCampsites.map((campsite) =>
                            campsite ? (
                                <Campsite
                                    key={campsite.id}
                                    campsite={campsite}
                                />
                            ) : null
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FavouritesList
