import { graphql } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { Seo } from '../../components/seo'
import PageTitle from '../../components/pageTitle'
import { Spacer } from '../../components/spacer'
import { FavouritesContext } from '../../providers/favouritesProvider'
import FavouritesList from '../../components/favouritesList'

const FavouritesPage = ({ data, location }) => {
    const searchParams = new URLSearchParams(location.search)
    const favouriteIds = searchParams.get('list')

    const { favourites } = useContext(FavouritesContext)

    const [filteredCampsites, setFilteredCampsites] = useState([])

    useEffect(() => {
        if (favouriteIds) {
            // Split the string of favouriteIds into an array
            const formattedFavouriteIds = favouriteIds.split(',')
            // Filter the campsites data based on the favourite IDs
            const filteredData =
                data.markdownRemark.frontmatter.campsites.filter((campsite) =>
                    formattedFavouriteIds.includes(campsite.id.toString())
                )
            setFilteredCampsites(filteredData)
        } else {
            // Use favourites from the provider
            const filteredData = favourites.map((id) =>
                data.markdownRemark.frontmatter.campsites.find(
                    (campsite) => campsite.id === id
                )
            )
            setFilteredCampsites(filteredData)
        }
    }, [data.markdownRemark.frontmatter.campsites, favouriteIds, favourites])

    const pageDesc =
        'You have ' +
        filteredCampsites.length +
        ' ' +
        (filteredCampsites.length === 1 ? 'Favourite' : 'Favourites')

    return (
        <>
            <PageTitle title="Favourite Campsites" text={pageDesc} />
            <Spacer />
            <FavouritesList filteredCampsites={filteredCampsites} />
        </>
    )
}

export default FavouritesPage

export const Head = ({ location }) => {
    return (
        <Seo
            title="Favourtite Camspites"
            description="Compare and share your favourite campsites"
            pathname={location?.pathname}
        ></Seo>
    )
}

export const pageQuery = graphql`
    {
        markdownRemark(frontmatter: { id: { eq: "campsites" } }) {
            frontmatter {
                metaTitle
                metaDesc
                PageTitle {
                    Title
                    Text
                }
                campsites {
                    id
                    title
                    caption
                    sustainability
                    description
                    link
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 1280)
                        }
                    }
                    attributes {
                        nearestTown
                        local
                        sustainabilityScore
                        campfire
                        pets
                        priority
                        perfectFor {
                            couples
                            families
                        }
                        activities {
                            hiking
                            swimming
                            beaches
                            surfing
                            historical
                            sailing
                            kayaking
                            stars
                            fishing
                            cycling
                        }
                        vibe {
                            lively
                            quiet
                            relaxing
                            rustic
                            familyFun
                        }
                    }
                }
            }
        }
    }
`
