import React, { useContext, useState, Fragment } from 'react'
import { useStyle } from '../../hooks/classify'
import { Dialog, Transition } from '@headlessui/react'
import * as defaultClasses from './ShareButton.module.css'
import { FavouritesContext } from '../../providers/favouritesProvider'
import DialogModule from '../dialogModel/DialogModel'
import EmailIcon from '../../images/icons/email'
import WhatsAppIcon from '../../images/icons/whatsApp'
import FacebookIcon from '../../images/icons/facebook'
import CloseIcon from '../../images/icons/Close'

const ShareButton = () => {
    const classes = useStyle(defaultClasses)
    const { favourites } = useContext(FavouritesContext)
    const [shareURL, setShareURL] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const message = 'Check out my favourite campsites!'

    const handleShareFacebook = () => {
        const facebookUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
            shareURL
        )}&quote=${encodeURIComponent(message)}`
        window.open(facebookUrl, '_blank')
    }

    const handleShareEmail = () => {
        const emailSubject = encodeURIComponent(message)
        const emailBody = encodeURIComponent(
            `Here are my favourite campsites: ${shareURL}`
        )
        const emailUrl = `mailto:?subject=${emailSubject}&body=${emailBody}`
        window.location.href = emailUrl
    }

    const handleShareWhatsApp = () => {
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${message}\n${shareURL}`
        )}`
        window.open(whatsappUrl, '_blank')
    }

    const handleShare = () => {
        const url = new URL(window.location.href)
        url.pathname = '/campsites/favourites'
        url.searchParams.set('list', favourites.join(','))
        setShareURL(url.href)
        setIsOpen(true)
    }

    if (!favourites || favourites.length === 0) {
        return null // Don't render anything if there are no favourites
    }

    return (
        <div>
            <button
                className={'btnPrimary ' + classes.button}
                onClick={handleShare}
            >
                Share Favourites
            </button>
            <DialogModule isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <CloseIcon className={classes.closeButton} />
                            <Dialog.Description>
                                <div className="text-center">
                                    Share your favourite campsites.
                                </div>
                            </Dialog.Description>
                            <div className={classes.socialLinkWrapper}>
                                <button
                                    className={classes.socialLink}
                                    onClick={handleShareWhatsApp}
                                >
                                    <WhatsAppIcon
                                        className={classes.socialIcon}
                                    />
                                    <span>WhatsApp</span>
                                </button>
                                <button
                                    className={classes.socialLink}
                                    onClick={handleShareFacebook}
                                >
                                    <FacebookIcon
                                        className={classes.socialIcon}
                                    />
                                    <span>Facebook</span>
                                </button>
                                <button
                                    className={classes.socialLink}
                                    onClick={handleShareEmail}
                                >
                                    <EmailIcon className={classes.socialIcon} />
                                    <span>Email</span>
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </DialogModule>
        </div>
    )
}

export default ShareButton
